import React from "react";
import Layout from "components/layout";
import SanFrancisco from "components/locationIndexPages/SanFrancisco";
import SEO from "components/seo";

export default function index() {
  const title = "San Francisco Translation Services - Translation Agency";
  const description =
    "Need translation services in San Francisco? Tomedes provides translation, localization, and interpretation solutions in 120+ languages with rapid delivery and 24/7 support.";
  const keywords = "Translation Services in San Francisco";
  return (
    <Layout>
      <SEO
        title={title}
        description={description}
        keywords={keywords}
        slug="/locations/san-francisco-translation-services"
      />
      <SanFrancisco />
    </Layout>
  );
}
